<template>
  <div class="page-wrapper">
    <b-container fluid>
      <bo-page-title></bo-page-title>
      <b-tabs>
        <b-tab title="Page Settings" active>
          <bo-card title="Featured Article">
            <template #filters>
              <div class="text-right">
                <b-button class="btn-rounded" variant="primary">
                  See More
                  <i class="fa fa-arrow-circle-right ml-2" />
                </b-button>
              </div>
            </template>
            <b-row cols="1" cols-md="2" cols-lg="4" class="gutter-2">
                <b-col>
                <div class="post-block">
                  <div class="post-block-media">
                    <b-img blank width="380" height="253" fluid-grow blank-color="#333" src=""
                      class="post-block__img" />
                    <b-badge variant="success" class="post-block__badge">Published</b-badge>
                    <div class="post-block-actions">
                      <b-button class="btn-icon" size="sm" variant="danger">
                        <i class="fas fa-trash-alt"></i>
                      </b-button>
                      <b-button class="btn-icon" size="sm" variant="warning">
                        <i class="fas fa-pencil-alt"></i>
                      </b-button>
                    </div>
                  </div>
                  <div class="post-block-body">
                    <span class="post-block__cat">Accommodation</span>
                    <h4 class="font-weight-bold post-block__title">Kemenparekraf Lirik Kota Malang, Kembangkan Pariwisata Era Society 5.0
                    </h4>
                    <span class="post-block__meta">
                      <i class="icon-user"></i> Administrator
                    </span>
                    <span class="post-block__meta">
                      <i class="ti-calendar"></i> {{ '2021/02/07 18:37' | moment('lll') }}
                    </span>
                  </div>
                  <div class="post-block-statistics">
                    <span class="post-block-statistics__item">
                      <i class="icon-eye"></i> 152
                    </span>
                    <span class="post-block-statistics__item">
                      <i class="icon-bubbles"></i> 22
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="post-block">
                  <div class="post-block-media">
                    <b-img blank width="380" height="253" fluid-grow blank-color="#333" src=""
                      class="post-block__img" />
                    <b-badge variant="success" class="post-block__badge">Published</b-badge>
                    <div class="post-block-actions">
                      <b-button class="btn-icon" size="sm" variant="danger">
                        <i class="fas fa-trash-alt"></i>
                      </b-button>
                      <b-button class="btn-icon" size="sm" variant="warning">
                        <i class="fas fa-pencil-alt"></i>
                      </b-button>
                    </div>
                  </div>
                  <div class="post-block-body">
                    <span class="post-block__cat">Accommodation</span>
                    <h4 class="font-weight-bold post-block__title">Kemenparekraf Lirik Kota Malang, Kembangkan Pariwisata Era Society 5.0</h4>
                    <span class="post-block__meta">
                      <i class="icon-user"></i> Administrator
                    </span>
                    <span class="post-block__meta">
                      <i class="ti-calendar"></i> {{ '2021/02/07 18:37' | moment('lll') }}
                    </span>
                  </div>
                  <div class="post-block-statistics">
                    <span class="post-block-statistics__item">
                      <i class="icon-eye"></i> 152
                    </span>
                    <span class="post-block-statistics__item">
                      <i class="icon-bubbles"></i> 22
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="post-block">
                  <div class="post-block-media">
                    <b-img blank width="380" height="253" fluid-grow blank-color="#333" src=""
                      class="post-block__img" />
                    <b-badge variant="success" class="post-block__badge">Published</b-badge>
                    <div class="post-block-actions">
                      <b-button class="btn-icon" size="sm" variant="danger">
                        <i class="fas fa-trash-alt"></i>
                      </b-button>
                      <b-button class="btn-icon" size="sm" variant="warning">
                        <i class="fas fa-pencil-alt"></i>
                      </b-button>
                    </div>
                  </div>
                  <div class="post-block-body">
                    <span class="post-block__cat">Accommodation</span>
                    <h4 class="font-weight-bold post-block__title">Pariwisata Labuan Bajo Kembali Mengeliat</h4>
                    <span class="post-block__meta">
                      <i class="icon-user"></i> Administrator
                    </span>
                    <span class="post-block__meta">
                      <i class="ti-calendar"></i> {{ '2021/02/07 18:37' | moment('lll') }}
                    </span>
                  </div>
                  <div class="post-block-statistics">
                    <span class="post-block-statistics__item">
                      <i class="icon-eye"></i> 152
                    </span>
                    <span class="post-block-statistics__item">
                      <i class="icon-bubbles"></i> 22
                    </span>
                  </div>
                </div>
              </b-col>
              <b-col>
                <div class="post-block">
                  <div class="post-block-media">
                    <b-img blank width="380" height="253" fluid-grow blank-color="#333" src=""
                      class="post-block__img" />
                    <b-badge variant="success" class="post-block__badge">Published</b-badge>
                    <div class="post-block-actions">
                      <b-button class="btn-icon" size="sm" variant="danger">
                        <i class="fas fa-trash-alt"></i>
                      </b-button>
                      <b-button class="btn-icon" size="sm" variant="warning">
                        <i class="fas fa-pencil-alt"></i>
                      </b-button>
                    </div>
                  </div>
                  <div class="post-block-body">
                    <span class="post-block__cat">Xperience</span>
                    <h4 class="font-weight-bold post-block__title">Pesona Cappadocia, Sebuah 'Dunia Lain' di Turki yang Wajib Dikunjungi
                    </h4>
                    <span class="post-block__meta">
                      <i class="icon-user"></i> Administrator
                    </span>
                    <span class="post-block__meta">
                      <i class="ti-calendar"></i> {{ '2021/02/07 18:37' | moment('lll') }}
                    </span>
                  </div>
                  <div class="post-block-statistics">
                    <span class="post-block-statistics__item">
                      <i class="icon-eye"></i> 152
                    </span>
                    <span class="post-block-statistics__item">
                      <i class="icon-bubbles"></i> 22
                    </span>
                  </div>
                </div>
              </b-col>
            </b-row>
          </bo-card>

          <bo-card title="Cerita Terpopuler">
            <template #filters>
              <div class="text-right">
                <b-button class="btn-rounded" variant="primary">
                  View All
                  <i class="fa fa-arrow-circle-right ml-2" />
                </b-button>
              </div>
            </template>
            <!--<b-form-checkbox>Show Section?</b-form-checkbox>-->
            <b-row cols="1" cols-md="12" cols-lg="12" class="gutter-2">
              <b-col>
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Category Name</th>
                        <th style="width: 30%;">Description</th>
                        <th>Slug</th>
                        <th>Count</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="blog_cat in blog" :key="blog_cat.id">
                        <td>{{blog_cat.no}}</td>
                        <td>{{blog_cat.cat_name}}</td>
                        <td>{{blog_cat.desc}}</td>
                        <td>{{blog_cat.slug}}</td>
                        <td>{{blog_cat.count}}</td>
                        <td><span class="badge badge-success">{{blog_cat.status}}</span></td>
                        <td>
                          <a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
                          <a href="#" class="icon_action"><i class="ti-settings"></i></a>
                          <a href="#" class="icon_action"><i class="ti-trash"></i></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>

            </b-row>
          </bo-card>

           <bo-card title="Accommodation">
            <template #filters>
              <div class="text-right">
                <b-button class="btn-rounded" variant="primary">
                  View All
                  <i class="fa fa-arrow-circle-right ml-2" />
                </b-button>
              </div>
            </template>
            <!--<b-form-checkbox>Show Section?</b-form-checkbox>-->
            <b-row cols="1" cols-md="12" cols-lg="12" class="gutter-2">
              <b-col>
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Category Name</th>
                        <th style="width: 30%;">Description</th>
                        <th>Slug</th>
                        <th>Count</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="blog_cat in blog" :key="blog_cat.id">
                        <td>{{blog_cat.no}}</td>
                        <td>{{blog_cat.cat_name}}</td>
                        <td>{{blog_cat.desc}}</td>
                        <td>{{blog_cat.slug}}</td>
                        <td>{{blog_cat.count}}</td>
                        <td><span class="badge badge-success">{{blog_cat.status}}</span></td>
                        <td>
                          <a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
                          <a href="#" class="icon_action"><i class="ti-settings"></i></a>
                          <a href="#" class="icon_action"><i class="ti-trash"></i></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>

            </b-row>
          </bo-card>

              <bo-card title="Xperience">
            <template #filters>
              <div class="text-right">
                <b-button class="btn-rounded" variant="primary">
                  View All
                  <i class="fa fa-arrow-circle-right ml-2" />
                </b-button>
              </div>
            </template>
            <!--<b-form-checkbox>Show Section?</b-form-checkbox>-->
            <b-row cols="1" cols-md="12" cols-lg="12" class="gutter-2">
              <b-col>
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Category Name</th>
                        <th style="width: 30%;">Description</th>
                        <th>Slug</th>
                        <th>Count</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="blog_cat in blog" :key="blog_cat.id">
                        <td>{{blog_cat.no}}</td>
                        <td>{{blog_cat.cat_name}}</td>
                        <td>{{blog_cat.desc}}</td>
                        <td>{{blog_cat.slug}}</td>
                        <td>{{blog_cat.count}}</td>
                        <td><span class="badge badge-success">{{blog_cat.status}}</span></td>
                        <td>
                          <a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
                          <a href="#" class="icon_action"><i class="ti-settings"></i></a>
                          <a href="#" class="icon_action"><i class="ti-trash"></i></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>

            </b-row>
          </bo-card>

            <bo-card title="Flight">
            <template #filters>
              <div class="text-right">
                <b-button class="btn-rounded" variant="primary">
                  View All
                  <i class="fa fa-arrow-circle-right ml-2" />
                </b-button>
              </div>
            </template>
            <!--<b-form-checkbox>Show Section?</b-form-checkbox>-->
            <b-row cols="1" cols-md="12" cols-lg="12" class="gutter-2">
              <b-col>
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Category Name</th>
                        <th style="width: 30%;">Description</th>
                        <th>Slug</th>
                        <th>Count</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="blog_cat in blog" :key="blog_cat.id">
                        <td>{{blog_cat.no}}</td>
                        <td>{{blog_cat.cat_name}}</td>
                        <td>{{blog_cat.desc}}</td>
                        <td>{{blog_cat.slug}}</td>
                        <td>{{blog_cat.count}}</td>
                        <td><span class="badge badge-success">{{blog_cat.status}}</span></td>
                        <td>
                          <a href="#" class="icon_action"><i class="ti-marker-alt"></i></a>
                          <a href="#" class="icon_action"><i class="ti-settings"></i></a>
                          <a href="#" class="icon_action"><i class="ti-trash"></i></a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>

            </b-row>
          </bo-card>

         
        </b-tab>
        <b-tab title="SEO Settings">
          <b-card no-body>
            <b-card-header>
              <b-card-title title-tag="h5">Home SEO Settings</b-card-title>
            </b-card-header>
            <b-form @submit.prevent="submitMethod">
              <b-card-body>
                <b-form-group label-for="homeMetaTitle">
                  <template #label>
                    Meta Title
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-input id="homeMetaTitle" placeholder="Meta Title" v-model="seoRow.title" />
                </b-form-group>
                <b-form-group label-for="homeMetaDesc">
                  <template #label>
                    Meta Description
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-textarea id="homeMetaDesc" placeholder="Meta Description" v-model="seoRow.description" />
                </b-form-group>
                <b-form-group label-for="homeMetaTags">
                  <template #label>
                    Meta Tags
                    <b-badge variant="info" pill class="help-badge"
                      v-b-tooltip.hover.right="'This field functions to ease Google understand the title of this website page. Pro tip: Make sure the characters length is less than 70 chars.'">
                      <i class="fas fa-question"></i>
                    </b-badge>
                  </template>
                  <b-form-tags id="homeMetaTags" placeholder="Type and press enter ..." remove-on-delete
                    tag-variant="primary" tag-class="text-white" v-model="seoRow.keywords" />
                </b-form-group>
              </b-card-body>
              <b-card-footer>
                <div class="text-right">
                  <b-button class="btn-rounded" type="submit" variant="primary">Submit</b-button>
                </div>
              </b-card-footer>
            </b-form>
          </b-card>
        </b-tab>
      </b-tabs>
    </b-container>
  </div>
</template>

<script>
  import GlobalVue from '@/libs/Global.vue'
  export default {
    name: 'BoHome',
    extends: GlobalVue,
    components: {},
    mounted() {
      this.loadSeoData()
    },
    data() {
      return {
        blog: [{
            no: 1,
            cat_name: 'Xperience',
            desc: 'Pesona Cappadocia, Sebuah Dunia Lain di Turki yang Wajib Dikunjungi',
            slug: 'Xperience',
            count: '3',
            status: 'Active'
          },
          {
            no: 2,
            cat_name: 'Accommodation',
            desc: 'Pariwisata Labuan Bajo Kembali Menggeliat',
            slug: 'Accommodation',
            count: '10',
            status: 'Active'
          },
          {
            no: 3,
            cat_name: 'Flight',
            desc: 'Tips Biar Bisa Liburan Tiap Tahun',
            slug: 'Flight',
            count: '20',
            status: 'Active'
          }
        ],
        selected: 'A',
        ads_device: [{
            item: 'A',
            name: 'Desktop'
          },
          {
            item: 'B',
            name: 'Mobile'
          }
        ],
        date: new Date(),
        days: [],
        featured: 'not_accepted',
        articleRow: {},
        articleData: [],
        rows: 30,
        perPage: 1,
        currentPage: 5,
        selected_category: 'All Category',
        category: [
          'All Category',
          'Accommodation',
          'Xperience',
          'Flight',
        ],
        selected_sort: 'Sort By',
        sort: [
          'Sort By',
          'Most Viewed',
          'Least Viewed',
          'Oldest',
          'Newest',
        ],
        
        viewMode: 'list',
        sliderCollapse: false,
        slideMode: 'Add',
        seoRow: {},
        slideId: undefined
      }
    },
    computed: {
      dataSrc() {
        return this.dummyData.home
      },
    },
    methods: {
      loadSliderData() {
        let datas = this.dataSrc.slider
        datas.forEach(el => {
          this.sliderData.push(el)
        })
      },
      loadSeoData() {
        let datas = this.dataSrc.seo
        this.seoRow = datas
      }
    }
  }
</script>